<template>
  <div>
    <base-btn
      v-for="(item, i) in items"
      :key="i"
      fab
      elevation="1"
      class="ma-2"
      :href="item.route"
      target="_blank"
      small
    >
      <v-icon
        dark
        v-text="item.icon"
      />
    </base-btn>
    <base-btn
      fab
      elevation="1"
      class="ma-2"
      :href="'mailto:pierrefacq.business@gmail.com'"
      small
    >
      <v-icon>mdi-email</v-icon>
    </base-btn>
  </div>
</template>

<script>
  export default {
    data: () => ({
      items: [
        {
          name: 'LinkedIn',
          icon: 'mdi-linkedin',
          route: 'https://www.linkedin.com/in/pierrefacqbusiness/',
        },
      ],
    }),
  }
</script>
